/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  function preventDefault(e) {
    e = e || window.event;
    if (e.preventDefault){
      e.preventDefault();
    }
    e.returnValue = false;
  }
/*
  var scrollState = false;
  function lockScroll() {
    if(!scrollState) {
      scrollState = true;
      if (window.addEventListener) {
        window.addEventListener('DOMMouseScroll', preventDefault, false);
      }
      window.onmousewheel = document.onmousewheel = preventDefault;
      return true;
    }
    return false;
  }
  function releaseScroll() {
    scrollState = false;
    if (window.removeEventListener) {
      window.removeEventListener('DOMMouseScroll', preventDefault, false);
    }
    window.onmousewheel = document.onmousewheel = null;
  }
*/
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.full-height').css('min-height', $(window).height());
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

          var lazyVideos = [].slice.call(document.querySelectorAll("video.lazyvideo"));
          if ("IntersectionObserver" in window) {
            var lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
              entries.forEach(function(video) {
                if (video.isIntersecting) {
                  for (var source in video.target.children) {
                    var videoSource = video.target.children[source];
                    if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                      videoSource.src = videoSource.dataset.src;
                    }
                  }

                  video.target.load();
                  video.target.classList.remove("lazyvideo");

                  if (video.intersectionRatio !== 1 && !video.target.paused) {
                      video.target.pause();
                  } else if (video.paused) {
                      video.target.play();
                  }

                  // lazyVideoObserver.unobserve(video.target);
                }
              }, { threshold: 0.2 });
            });

            lazyVideos.forEach(function(lazyVideo) {
              lazyVideoObserver.observe(lazyVideo);
            });
          }
      }
    },/*,
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        if(window.pageYOffset === 0) {
          $(window).one('scroll', function(){
            var body = $("html, body");
            lockScroll();
            body.stop().animate({scrollTop: $('.header-grey').offset().top}, 800, 'swing', releaseScroll);
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },*/
    'case_studies': {
      init: function() {
        (function verticalCenterCaseStudiesBlock() {
          var windowHeight = $(window).height() - 120;
          var summariesHeight = $('.case-studies-summaries').height();
          var margin = (windowHeight - summariesHeight) / 3;
          $('.case-studies-summaries').css('margin-top', Math.max(margin,0));
        })();
/*
        setTimeout(function(){
          var sections = [
            '#business-tech-consulting',
            '#ui-ux-design',
            '#marketing-advertising',
            '#event-systems'
          ];
          var lastScrollTop = 0;
          var lastSection;
          $(window).on('scroll', function() {
            var st = $(this).scrollTop();
            var testPos = st;
            var direction = 'up';
            if(st > lastScrollTop) {
              testPos += $(window).height();
              direction = 'down';
            }
            var section;
            for(var i = sections.length-1; i >=0; i--){
              var sectionTop = $(sections[i]).offset().top;
              var sectionHeight = $(sections[i]).outerHeight();
              if(testPos > sectionTop) {
                section = i;
                break;
              }
            }
            if(lastSection !== section && typeof(section) !== 'undefined'){
              if(lockScroll()){
                var body = $("html, body");
                var target = 0;
                if(direction === 'down'){
                  target = $(sections[section]).offset().top;
                } else {
                  target = $(sections[lastSection]).offset().top - $(window).height() + 1;
                }
                body.stop().animate({scrollTop: target}, 800, 'swing', releaseScroll);
              }
            }
            lastScrollTop = st;
            lastSection = section;
          });
        }, 100);

        $('.case-studies-button').click(function(e){
          e.preventDefault();
          if(lockScroll()){
            var body = $("html, body");
            var target = $(e.currentTarget).attr('href');
            var targetTop = $(target).offset().top;
            body.stop().animate({scrollTop: targetTop}, 800, 'swing', releaseScroll);
          }
        });*/
      }
    }/*,
    'team': {
      init: function() {
        // JavaScript to be fired on the team page
        if(window.pageYOffset === 0) {
          $(window).one('scroll', function(){
            var body = $("html, body");
            lockScroll();
            body.stop().animate({scrollTop: $('.header-grey').offset().top}, 800, 'swing', releaseScroll);
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on the team page, after the init JS
      }
    },
    'vision': {
      init: function() {
        // JavaScript to be fired on the vision page
        if(window.pageYOffset === 0) {
          $(window).one('scroll', function(){
            var body = $("html, body");
            lockScroll();
            body.stop().animate({scrollTop: $('.header-grey').offset().top}, 800, 'swing', releaseScroll);
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on the vision page, after the init JS
      }
    }*/
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);



})(jQuery); // Fully reference jQuery after this point.
